import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import WelcomeCard from '../components/WelcomeCard';
import WelcomeCard2 from '../components/WelcomeBox2'

import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';

import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';

import { projects } from '../settings/config';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://sublimation.app/">
      Sublimation.App
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: '0 auto',
});




  
export default function Album() {
  return (
    <>
      <AppBar position="relative">
        <Toolbar>
          <CameraIcon sx={{ mr: 2 }} />
          <Typography variant="h6" color="inherit" noWrap>
          Sublimation.App
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            display:"flex",
            flexDirection:"column"
          }}
        >
          <div style={{flex:1,   display:"flex", alignItems:"self-start", justifyContent:"space-evenly" , flexWrap:"wrap" }}>
     

          <WelcomeCard2 image={"/images/screenshot3.jpg"} title={"11oz"} desc={"11oz Mug Editor"} linkHref={`${projects.mugs.url}`}  disabled={!projects.mugs.enabled}/>
   

          <WelcomeCard2 image={"/images/cones.jpg"} title={"Cones calculator"} desc={"12oz/17oz and other cones calculator"}  linkHref={`${projects.cones.url}`}  disabled={!projects.cones.enabled} />
            <WelcomeCard2 image={"/images/print.jpg"} title={"Print"} desc={"Print templates widget"}   linkHref={`${projects.print.url}`}  disabled={!projects.print.enabled} />
            <WelcomeCard2 image={"/images/crop2.jpg"} title={"Crop"} desc={"Various products crop template"}  linkHref={`${projects.crop.url}`}  disabled={!projects.crop.enabled} />
          

            <WelcomeCard2 image={"/images/ceramix3.jpg"} title={"Ceramics"} desc={"Ceramics print widget"}    linkHref={`${projects.ceramix.url}`}  disabled={!projects.ceramix.enabled} />
            <WelcomeCard2 image={"/images/shopexp.jpg"} title={"Shop"} desc={"Shop export widget"}  linkHref={`${projects.shop.url}`}  disabled={!projects.shop.enabled} />
          




            
         </div>


         <Stack
              sx={{ pt: 4 , flexGrow:0, display:"none"}}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button variant="contained" href="/widget" >Editor</Button>
              <Button variant="outlined">Secondary action</Button>
            </Stack>
        </Box>
 
      </main>
      {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', p: 6 ,  display:"none"}} component="footer">
        {/* <Typography variant="h6" align="center" gutterBottom>
          Footer
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Something here to give the footer a purpose!
        </Typography> */}
        <Copyright />
      </Box>
      {/* End footer */}
    </>
  );
}