import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import { Link as MuiLink, Box, Button } from '@mui/material';

type WelcomeCardProps = {
    image:string
    title:string
    desc:string
    linkText?:string
    linkHref?:string
    disabled?:boolean
}


export default function ImgMediaCard(props:WelcomeCardProps) {
    const {image, title, desc, linkText="Check Out", linkHref="#", disabled=true} = props;
  return (
    <Card sx={{ 

        flexBasis:"25%",
        margin:2,
        borderRadius:0,

    }}>

    
 
      <CardMedia
        component="img"
        alt="green iguana"
        height="309"
        image={image}

        sx={{
          backgroundSize: "contain",
          objectFit:"contain"
        }}
      />
      <CardContent
      
      sx={{
        display: "flex",
        flexDirection:"row",
        justifyContent:"space-between",
        alignItems:"flex-end"
      }}
      >

        <Box>
            <Typography gutterBottom variant="h2" component="div" sx={{
                    color: "#263238",
                    fontSize: "16px !important",
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    lineHeight: "20px",
                    letterSpacing: "-0.05px",
                    textDecoration:"none"
            }}>
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
            {desc}
            </Typography>
        </Box>

        <Box>  

       <Button
       //   component="button"
          variant="outlined"
          href={linkHref}
          disabled={disabled}
        >
          {linkText}
        </Button>



        </Box>
      </CardContent>
   
 
    </Card>
  );
}