// until "top level await" is available without tweaks fetching config synchronous way
// https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Synchronous_and_Asynchronous_Requests#example_http_synchronous_request
const request = new XMLHttpRequest();
request.open('GET', '/config.json', false);  // `false` makes the request synchronous
request.setRequestHeader("Content-Type", "application/json");
request.setRequestHeader("Accept", "application/json");
request.send(null);


let configJson = {
    rootServerDomain:null,
    scheme: "https"
}

if (request.status === 200) {
    //console.log("[settings/api.ts] config loaded", request)
    configJson = JSON.parse(request.responseText)  

}

const {scheme, rootServerDomain} = configJson


const projects = {
    cones:{
        subdomain:"cones",
        url: `${scheme}://cones.${rootServerDomain}`,
        enabled:true
    },
    ceramix:{
        subdomain:"ceramix",
        url: `${scheme}://ceramix.${rootServerDomain}`,
        enabled:true
    },
    crop:{
        subdomain:"crop",
        url: `${scheme}://crop.${rootServerDomain}`,
        enabled:true
    },
    mugs:{
        subdomain:"mugs",
        url: `${scheme}://mugs.${rootServerDomain}`,
        enabled:true
    },
    print:{
        subdomain:"print",
        url: `${scheme}://print.${rootServerDomain}`,
        enabled:false
    },
    shop:{
        subdomain:"shop",
        url: `${scheme}://shop.${rootServerDomain}`,
        enabled:false
    },

}


export {projects}
